.ey-radio-group {
  display: flex;
}

.ey-radio-group input[type='radio'] {
  display: none;
}

.ey-radio-group .radio-label {
  display: flex;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 30px;
  border: 1px solid $border-color;
  border-left: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  min-width: 90px;
  cursor: pointer;
}


.ey-radio-group .radio-label.selected {
  background-color: $ey-yellow;
}

.ey-radio-group .radio-label.inactive {
  filter: grayscale(100%) opacity(50%);
}

.ey-radio-group .radio-label:first-child {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-left: 1px solid $border-color;
}

.ey-radio-group .radio-label:last-child {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.ey-radio-group .radio-content {
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 1px;
}

.macro-desc-radio-group {
  .ey-radio-group {
    .radio-label:first-child {
      border-left: 0;
    }

    .radio-label {
      padding: 8px 16px;
      margin-right: 2px;
      border: none;
    }

    .radio-label:not(.selected) {
      background-color: #EEEEF2;
    }
  }
}