.ey-indicator-grid-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  margin-top: 10px;
  position: relative;

  .MuiLinearProgress-root {
    position: absolute;
    width: 100%;
  }
}

.ey-indicator-grid-view .grid-view-toolbar {
  display: flex;
  flex-direction: row;
}

.ey-indicator-grid-view .groupby-toolbar-wrapper {
  margin-right: 15px;
}

.ey-indicator-grid-view .paginator-wrapper {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.ey-indicator-grid-view .settings-toolbar {
  margin-right: 15px;
  display: flex;
  flex-direction: row;
}

.ey-indicator-grid-view .grid-view {
  margin: 5px;
  flex: 1;
}


