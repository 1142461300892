//.ey-root-layout {
//  display: flex;
//  flex-grow: 1;
//  flex-direction: column;
//  min-height: 0;
//  overflow-x: auto;
//
//  main {
//    display: flex;
//    flex-direction: column;
//    flex: 1;
//    min-height: 0;
//  }
//}
//
// {
//  flex: 0 0 50px;
//  background-color: $ey-black;
//  color: $ey-white;
//  display: flex;
//  align-items: center;
//  padding: 0px 11px;
//  width: fit-content;
//
//  .nav-disabled {
//    cursor: help;
//    height: 100%;
//    opacity: 0.5;
//  }
//
//  .ey-logo {
//    width: 33px;
//    height: 33px;
//
//    img {
//      width: 100%;
//      height: 100%;
//    }
//  }
//}
//
//@media(min-width: 1200px) {
//  .ey-navbar {
//    width: auto;
//  }
//}
//
//.eyNavbarDev {
//  background-color: #00356B;
//}
//
//.ey-navbar .main-menu-wrapper {
//  height: 100%;
//  display: flex;
//  margin-left: 11px;
//  flex-direction: column;
//  flex: 1;
//}
//
//.ey-navbar .version-dropdown-wrapper {
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//  width: 300px;
//  width: 100%;
//
//  span {
//    font-size: 13px;
//  }
//
//  .versionCnt {
//    margin-right: 8px;
//  }
//
//  .ms-Dropdown-title {
//    border-radius: 5px;
//  }
//}
//
//.ey-navbar .ey-user {
//  display: flex;
//  align-items: center;
//
//  .user {
//    font-size: 14px;
//    color: $ey-yellow;
//    text-transform: capitalize;
//  }
//
//  .active-language {
//    font-size: 14px;
//    color: $ey-yellow;
//  }
//
//  .user-logout {
//    margin-left: 15px;
//    list-style-type: none;
//    text-decoration: none;
//    margin-right: 15px;
//    color: $ey-white;
//    font-size: 14px;
//    position: relative;
//
//    &:after {
//      content: '';
//      height: 20px;
//      width: 2px;
//      background: $ey-white;
//      position: absolute;
//      right: -9px;
//      top: 0;
//    }
//  }
//}

.version-select {
  width: 350px;
}

.app-version {
  font-size: 16px;
  z-index: 20;
  margin-left: 10px
}
.license-expired {
  font-size: 18px;
  z-index: 20;
  margin-left: 10px;
  color: red;

}
.license-expired:hover {
  text-decoration: underline;
  cursor: pointer;
}

footer {
  display: flex;
  background: darken($ey-black, 10%);
  padding: 10px 11px;
  color: $ey-white;

  .spacer {
    display: flex;
    flex: 1;
  }
}

.paginator-centered {
  margin: auto
}

#drawer-filter-menu .MuiButton-startIcon {
  padding: 3px !important;
  margin-top: -2px;
  margin-right: 2px;
  margin-bottom: -2px;
  margin-left: 2px;
}

canvas {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px !important;
}


.license-modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.license-modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  color: black;
}


/* The Close Button */
.license-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.license-close:hover,
.license-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}