.ey-calc-toolbar {
  display: flex;
  align-items: center;
  margin: 3px 5px 4px 5px;
}

.ey-calc-toolbar .spacer {
  flex: 1;
}

.ey-calc-toolbar .version-select {
  width: 300px;
}

.ey-calc-toolbar .oil-field-select {
  width: 300px;
  margin-right: 5px;
}
