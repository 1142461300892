.ey-well {
  transition: 0.3s;
  border-radius: 15px;
  border-style: ridge;
  padding: 3px;
  // margin: 5px;
  // width: 250px;
  height: 70px;
}

.enabled {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}

.ey-well.expanded {
  height: 200px;
}

.ey-well-header {
  display: flex;
  justify-content: space-between;
}

.ey-wheel-header-type {
  border-left: 1px dotted $border-color;
  border-bottom: 1px dotted $border-color;
  font-weight: bolder;
  font-size: larger;
}

.ey-well-params {
  margin-top: 0;
  margin-left: 0;
}

.ey-gas-icon {
  width: 20px;
  height: 20px;
  background: url('../../../../assets/wells/gas.svg') center;
  background-size: contain;
}

.ey-water-icon {
  width: 20px;
  height: 20px;
  background: url('../../../../assets/wells/water_drop.svg') center;
  background-size: contain;
}

.ey-oil-icon {
  width: 20px;
  height: 20px;
  background: url('../../../../assets/wells/oil_drop.svg') center;
  background-size: contain;
}

.ey-well-prod-values {
  display: flex;
  justify-content: space-between;
}

.ey-param-warper {
  display: flex;
  flex-wrap: nowrap;
}

.ey-well-plot {
  width: 200px;
  height: 120px;
  z-index: 9999;
}
