.ey-disable-Wells-page {
  display: grid;
  padding: 20px 11px;
  height: 100vh;
  overflow-y: scroll;
}

.ey-well-container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
