.ey-tool-button {
  width: 36px;
  flex-shrink: 0;
  height: 36px;
  user-select: none;
  border: 1px solid #8a8886;
  border-right-width: 0;

  &.small {
    width: 22px;
    height: 22px;
  }
}

.ey-tool-button button {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  outline: none;
}

.ey-tool-button.first {
  border-left-width: 1px;
}

.ey-tool-button.last {
  border-right-width: 1px;
}

/*.ey-tool-button button:disabled {*/
/*    color: #EBEBE4;*/
/*    border-color: #EBEBE4;*/
/*}*/

.ey-tool-button button:hover:not(:disabled) {
  background-color: $ey-white;

}

.ey-tool-button button:active:not(:disabled) {
  background-color: $ey-yellow;
}

.ey-tool-button button > div {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ey-tool-button button:disabled > div {
  filter: grayscale(100%) opacity(50%);
}

.ey-tool-button span.tool-button-content {
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 1px;
}
