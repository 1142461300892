// Active filter cell
.ag-header-cell-filtered {
    outline: 1px solid #969696;
    background: #b3b3b3;
}

// input:disabled {
//     background-color: #c4c4cd;
// }

.data-view-wrapper {
    .ag-header {
        height: 45px !important;
        min-height: 45px !important;
    }

    .ag-theme-balham {
        .ag-row:not(.ag-row-first) {
            border-top-style: none !important;
        }

        .ag-row {
            border-color: transparent !important;
        }

        .ag-header {
            .ag-header-row-column {
                min-height: 48px !important;
                height: 48px !important;
            }

            .ag-header-row-column .ag-header-cell-text {
                color: #2e2e38;
            }
        }

        .ag-icon-menu:before {
            content: url("../../assets/images/icons/table/icon-filter.svg") !important;
        }

        // POPUP FILTER AG GRID STYLES
        .ag-root-wrapper {
            overflow: unset !important;
        }

        .ag-root-wrapper {
            position: unset !important;
        }

        .ag-theme-balham .ag-popup-child:not(.ag-tooltip-custom) {
            z-index: 999;
            overflow-y: unset !important;
        }
        // END - POPUP FILTER AG GRID STYLES

        // CUSTOM SELECTED ROW AG GRID STYLES
        .ag-row-selected {
            background-color: #fcfdfe;
            
            div[col-id="COLOR"] {
                &:before {
                    content: url("../../assets/images/icons/other/crossed-eye.svg");
                    position: relative;
                    top: 4px;
                }
            }
        }
        // CUSTOM SELECTED ROW AG GRID STYLES
    }
}

.hidden {
    display: none;
}

.ey-indicator-grid-view .ag-root-wrapper {
    height: calc(100% - 30px);
    border-radius: 0;
    border-left: 0;
    border-right: 0;
}

.ag-theme-balham .ag-root-wrapper {
    border-radius: 0;
}

// react-resizable

.react-resizable {
    position: relative;
    min-height: 40px;
}

.react-resizable-handle {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    border-top: 2px solid #e7e7ea;
    left: 0;
    bottom: 0;
    transition: 0.3s;
    z-index: 10;

    &:before {
        position: absolute;
        left: 50%;
        bottom: -13px;
        transform: translateX(-50%);
        content: url("../../assets/images/icons/other/icon-resize-square.svg");
        cursor: grab;
    }

    &:after {
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        content: url("../../assets/images/icons/other/icon-resize.svg");
        width: 20px;
        height: 20px;
        cursor: grab;
    }

    &:hover {
        border-color: #eeeef2;

        &:before {
            content: url("../../assets/images/icons/other/icon-resize-square-hover.svg");
        }
    }
}

.macro-scenarios-table-wrapper {
    .ag-theme-balham .ag-header {
        background: #fff;
        height: 64px !important;
    }

    .ag-theme-balham .ag-root-wrapper {
        border-left: 0;
        border-right: 0;
    }
}

.ag-horizontal-left-spacer {
    border: none !important;
}

.ag-root {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0) 49.9%, #e7e7ea 49.9%, #e7e7ea 60%, rgba(0, 0, 0, 0) 60%) fixed,
        linear-gradient(45deg, #e7e7ea 10%, rgba(0, 0, 0, 0) 10%) fixed;
    background-size: 0.5em 0.5em;

    .ag-body-horizontal-scroll {
        background: #ffffff;
    }

    .ag-theme-balham {
        .ag-row:nth-child(2n) {
            background: #f6f6f9;
        }

        .ag-row-hover {
            background-color: inherit !important;

            &:nth-child(2n) {
                background-color: #eeeef2 !important;
            }
        }
    }
}

.ag-menu {
  opacity: 0;
  max-height: none;
}
