* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-family: 'EYInterstate', Helvetica, Arial, sans-serif;
}

html,
body {
    color: #2e2e38;

    div[role='tooltip'][style='position: fixed; top: 0px; left: 0px;'] {
        width: 0;
        height: 0;
    }
}

.Mui-checked {
    //color: #2db757 !important;
    color: #2e2e38 !important;
}

h1.ey-page-title {
    font-size: 22px;
    margin: 20px 10px 15px 10px;
    user-select: none;
}

h2.ey-section-title {
    font-size: 16px;
    margin: 20px 10px 10px 10px;
    user-select: none;
}

.ey-tool-icon {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}

.ey-tool-icon-small {
    background-size: contain;
    background-repeat: no-repeat;
    width: 17px !important;
    height: 17px !important;
}

.ey-tool-icon-sum {
    background-image: url('../../assets/tool-icons/tool-icon-sum.svg');
}

.ey-tool-icon-agg {
    background-image: url('../../assets/tool-icons/tool-icon-agg-col.svg');
}

.ey-tool-icon-no-zero {
    background-image: url('../../assets/tool-icons/tool-icon-no-zero.svg');
}

.tool-icon-prec-decr {
    background-image: url('../../assets/tool-icons/tool-icon-prec-decr.svg');
}

.tool-icon-prec-incr {
    background-image: url('../../assets/tool-icons/tool-icon-prec-incr.svg');
}

.tool-icon-expand-all {
    background-image: url('../../assets/tool-icons/tool-icon-expand-all.svg');
}

.tool-icon-collapse-all {
    background-image: url('../../assets/tool-icons/tool-icon-collapse-all.svg');
}

.tool-icon-show-all-indicators {
    background-image: url('../../assets/tool-icons/tool-icon-show-all-indicators.svg');
}

.tool-icon-jump-to-current {
    background-image: url('../../assets/tool-icons/tool-icon-jump-to-current.svg');
}

.tool-icon-contains {
    background-image: url('../../assets/tool-icons/tool-icon-contains.svg');
}

.tool-icon-startswith {
    background-image: url('../../assets/tool-icons/tool-icon-startswith.svg');
}

.tool-icon-case-sensitive {
    background-image: url('../../assets/tool-icons/tool-icon-case-sensitive.svg');
}

.tool-icon-exact {
    background-image: url('../../assets/tool-icons/tool-icon-exact.svg');
}

.tool-icon-fz {
    background-image: url('../../assets/tool-icons/tool-icon-fz.svg');
}

.tool-icon-regex {
    background-image: url('../../assets/tool-icons/tool-icon-regex.svg');
}

.ey-tool-icon-avg:before {
    content: '\e3a4';
    font-family: 'Material Icons';
    font-size: 25px;
}

.react-resizable {
    width: 100% !important;
}

.no-data-message {
    margin: auto;
}
