.ey-tree-controller {
  background-color: #2E2E38;
  padding: 5px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;

  .spacer {
    flex-basis: 10px;
  }

  .spacer-fill {
    flex-grow: 1;
  }
}
