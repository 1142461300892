// import main styles
@import './styles/system/vars';
@import './styles/system/fonts';
@import './styles/system/base';
// import components
@import './styles/components/DropZone';
@import './styles/components/EYIcon';
@import './styles/components/RadioGroup';
@import './styles/components/ToolButton';
@import './styles/components/ToolCheckbox';
@import './styles/components/IndicatorTree';
@import './styles/components/TreeController';
// import layout
@import './styles/layout/RootLayout';
// calculation page components
@import './styles/pages/calculation-page/components/CalcToolbar';
@import './styles/pages/calculation-page/components/GroupByToolbar';
@import './styles/pages/calculation-page/components/IndicatorGridView';
@import './styles/pages/calculation-page/components/IndicatorPlotView';
@import './styles/pages/calculation-page/components/IndicatorPanel';
// calculation page
@import './styles/pages/calculation-page/CalculationPage';
// dashboard page
@import './styles/pages/dashboard-page/DashboardsPage';
// disable wells page components
@import './styles/pages/disable-wells-page/components/Well';
// disable wells page
@import './styles/pages/disable-wells-page/DisableWellsPage';
// import page
@import './styles/pages/import-page/ImportPage';
// login page
@import './styles/pages/login-page/LoginPage';
// start page
@import './styles/pages/start-page/StartPage';
// geo map page
@import './styles/pages/geo-map/GeoMap';
// 404 page
@import './styles/pages/notFound-page/NotFound';

@import './styles/overrides/MuiAutocomplete';
@import 'styles/overrides/MuiTabs';
@import 'styles/overrides/MuiMenuList';
@import 'styles/overrides/MuiButton';
@import 'styles/overrides/MuiAccordion';

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
}

#root {
    height: 100%;
}
