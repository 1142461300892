// ey colors
$ey-black: #2e2e38;
$ey-white: #f6f6fa;
$ey-yellow: #ffe600;
$ey-gray-01: #747480;
$ey-gray-02: #C4C4CD;
$ey-gray-03: #C4C4CD;
$ey-red-04: #ff4136;
$ey-red-02: #ff736a;
$ey-blue-03: #35a4e8;

$ey-green: #2DB757;

// tree levels
$ey-tree-level-1: #2e2e38;
$ey-tree-level-2: #1A1A24;
$ey-tree-level-3: #747480;
$ey-tree-level-4: #1A1A24;
$ey-tree-level-5: #2e513d;
$ey-tree-level-6: #3f7255;
$ey-tree-level-7: #2f7f22;

// custom colors
$success: #2DB757;
$error: #FF4136;
$border-color: #8a8886;
$button-disabled: #ebebe4;

// transition
@mixin transition {
  transition: all 0.25s ease;
}
