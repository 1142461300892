.ey-import-page {
  display: flex;
  flex-direction: column;
  padding: 25px;

  .ey-section-title {
    margin-bottom: 0
  }

  .ey-paginator {
    padding: 10px;
  }
}

.dropzone-wrapper {
  margin: 10px;
}

.data-list-wrapper {
  height: 100%;
  overflow-y: scroll;
}

.ey-status-bage {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: var(--weight-light);
  justify-content: center;
  border-radius: 14px;
  font-size: 9pt;
}

.ey-status-bage.status-new {
  background: $ey-black;
  color: $ey-white;
}

.ey-status-bage.status-in-progress {
  background: $ey-gray-01;
  color: $ey-white;
}

.ey-status-bage.status-in-progress .ms-Spinner {
  margin-right: 4px;
}

.ey-status-bage.status-success {
  background: $success;
  color: $ey-white;
}

.status-error {
  background: $ey-red-04;
  color: $ey-white;
}

.ey-status-bage.status-revoked {
  background: $ey-red-04;
  color: $ey-white;
}

.ey-status-bage.status-retry {
  background: $ey-red-04;
  color: $ey-white;
}

.status-error .error-extra {
  color: black;
  margin: 0.5rem 1rem 0.5rem 2rem;
}

.status-warning .warning-extra {
  color: green;
  margin: 0.5rem 1rem 0.5rem 2rem;
}

.deleteIcon {
  color: $ey-red-04 !important;
}

.MuiTableRow-head {
  & .MuiTableCell-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  & .MuiTableCell-head {
    padding-top: 9px;
    padding-bottom: 9px;
    border-bottom: 0;
    border-right: 0;
    position: relative;
    // border-bottom: 1px solid $ey-gray-03;
    // border-top: 1px solid $ey-gray-03;

    &:not(.selection-column-header):not(.columns-column-header):not(.hierarchy-column-header):not(.actions-column-header):not(.id-column-header)::after {
      content: '';
      width: 1px;
      height: 16px;
      background-color: $ey-gray-02;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.no_padding {
  padding-top: 0px !important;
}

@import url(https://fonts.googleapis.com/css?family=Ubuntu:700&subset=latin,latin-ext);
//HOVER TRANSITION
$hover: all 0.2s ease-in;
$slide: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
//COLORS
$grey: #4f4f4f;
$white: #ffffff;
$green: #39bda7;
$transparent: rgba(255, 255, 255, 0);
$black-05: rgba(0, 0, 0, 0.05);

a {
  text-decoration: none;
  color: $grey;
}

//ELEMENTS

.accordion__button {
  width: inherit !important;
}

.ms-DetailsRow-cell {
  white-space: normal !important;
  word-wrap: normal;
}

.common_link:hover {
  text-decoration: underline;
  color: blue;
}

.red-icon {
  color: red;

}

.ey-paginator {
  float: right;
  margin-right: 10px;
}

.modal_body {
  flex: 4 4 auto;
  padding: 5px 28px;
  overflow-y: hidden;

}

.modal_header {
  flex: 1 1 auto;
  background: lightcoral;
  justify-content: space-between;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 28px;
  min-height: 40px;

}

.react-paginate {
  height: 50px;

  width: auto;
  display: block;
  margin: 5px auto 10px;
  font-size: 14px;
}

@keyframes hoverAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.react-paginate ul {
  display: inline-block;
  margin: 7px 0;
  padding-left: 0;
}

.react-paginate li {
  display: inline-block;
  border: 1px solid rgb(224, 224, 224);
  color: #000;
  cursor: pointer;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-align: center;
  margin: auto 3px;
}

.react-paginate li a {
  padding: 10px;
  outline: none;
}

.react-paginate li.active {
  border: 1px solid #2ecc71;
  border-radius: 100%;
  //background: rgb(224, 224, 224);
  outline: none;
}

.statndat_link:hover {
  text-decoration: underline;
}