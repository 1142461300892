.ey-tool-checkbox {
  width: 36px;
  height: 36px;
  user-select: none;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: none;
  border: 1px solid $border-color;
  border-right-width: 0;
  margin: 0;
  outline: none;

  &.small {
    width: 22px;
    height: 22px;
  }
}

.ey-tool-checkbox.first {
  border-left-width: 1px;
}

.ey-tool-checkbox.last {
  border-right-width: 1px;
}

.ey-tool-checkbox input[type='checkbox'] {
  display: none;
}

.ey-tool-checkbox.disabled > div {
  filter: grayscale(100%) opacity(50%);
}

.ey-tool-checkbox:hover:not(.disabled) {
  background-color: $ey-white;
}

.ey-tool-checkbox.checked:not(.disabled) {
  background-color: $ey-yellow;
}

.ey-tool-checkbox span.tool-checkbox-content {
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 1px;
}
