.ey-dropzone {
  display: flex;
  align-items: center;
  justify-items: center;
  outline: none;

  .dropzone-inner {
    flex: 1;
    border: 1px dashed $border-color;
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      border: 1px dashed $ey-black;
    }

    .drag-active {
      background-color: $ey-yellow;
    }
  }

  .success {
    color: $success;
  }

  .error {
    color: $error;
  }

  .reset-link {
    color: blue;
    font-size: 14px;
    margin-left: 0.3em;
  }
}

.ey-dropzone .dropzone-inner .ey-dropzone .message,
.ey-dropzone .progress,
.ey-dropzone .error {
  font-size: 14px;
  margin: 15px;
}
