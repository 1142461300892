.ey-indicator-panel {
  display: flex;
  flex-direction: column;
  color: #2e2e38;
  background-color: $ey-black;
  max-width: 335px;

  > header {
    background-color: #f6f6fa;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
  }

  h2 {
    margin: 10px 5px 10px 5px;
    padding: 0;
    color: #2e2e38;
    font-weight: normal;
    font-size: 18px;
    user-select: none;
  }

  .tree-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }

  .ms-SearchBox {
    border-radius: 4px;
  }

  .search {
    margin: 5px 5px 10px;
  }
}
