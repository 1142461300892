.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: $ey-black;

  h1 {
    color: $ey-white;
  }
}

.logo {
  width: 300px;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
  }
}

.login-form {
  max-width: 300px;
  width: 100%;
  border-radius: 2px;
  padding: 10px;

  .form-group:not(:last-child) {
    margin-bottom: 10px;
  }

  .login-form__input {
    input {
      color: $ey-gray-01;
      font-size: 14px;
      width: 100%;
      border-radius: 2px;
      background-color: $ey-white;
      border: none;
      outline: none;
      padding: 10px 20px;
      display: block;
      transition: all 0.3s;
    }

    .input-icon {
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      speak: none;
      pointer-events: none;
      position: absolute;
      bottom: 5px;
      right: 8px;
      top: auto;
      font-size: 16px;
      line-height: 18px;
      color: $ey-gray-01;
    }
  }

  button {
    display: block;
    width: 100%;
    padding: 8px 20px;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 2px;
    color: $ey-black;
    position: relative;
    background: #c4c4cd;
    overflow: hidden;
    z-index: 1;
    @include transition;
  }

  .btn-login:hover {
    &:before {
      right: 0;
    }
  }

  .btn-login:before {
    content: '';
    position: absolute;
    background: $ey-yellow;
    bottom: 0;
    left: 0;
    right: 100%;
    top: 0;
    z-index: -1;
    transition: all 0.3s ease-in;
  }
}

.login-error span {
  font-size: 15px;
  color: $ey-red-04;
}
