$ey-tree-level-indent: 5px;

.ey-indicator-tree {
    user-select: none;
    
    .tree-node {
        border-bottom: 1px solid #8e8e8e;

        :last-child {
            border-bottom: none;
        }

        > header {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            min-height: 32px;
            color: #f6f6fa;
            font-size: 14px;
            cursor: pointer;
            position: relative;
        }

        .icon-expand-collapse {
            margin-left: 10px;
            font-size: 14px;
            user-select: none;
            font-weight: 600;
        }

        &.expanded {
            > header > .icon-expand-collapse {
                transform: rotate(90deg);
            }
        }

        &.level-1 {
            background-color: $ey-tree-level-1;

            .item-title {
                color: #b3b3bc;
            }
        }

        &.level-2 {
            background-color: $ey-tree-level-2;
            margin-left: $ey-tree-level-indent;

            .item-title {
                color: #ffffff;
            }
        }

        &.level-3 {
            background-color: $ey-tree-level-3;
            margin-left: $ey-tree-level-indent;
        }

        &.level-4 {
            background-color: $ey-tree-level-4;
            margin-left: $ey-tree-level-indent;
        }

        &.level-5 {
            background-color: $ey-tree-level-1;
            margin-left: $ey-tree-level-indent;
        }

        &.level-6 {
            background-color: $ey-tree-level-2;
            margin-left: $ey-tree-level-indent;
        }

        &.level-7 {
            background-color: $ey-tree-level-3;
            margin-left: $ey-tree-level-indent;
        }

        &.level-8 {
            background-color: $ey-tree-level-4;
            margin-left: $ey-tree-level-indent;
        }

        .active {
            background-color: #ffe600;

            .item-title {
                color: $ey-black;
            }
        }

        .item-title {
            margin: 4px 18px 4px 6px;
            padding: 2px;
            font-size: 12px;
            font-family: 'EYInterstate Light', sans-serif;
        }

        &.found {
            > header > .item-title {
                background-color: yellow !important;
                color: $ey-black !important;
            }
        }

        .active-bullet {
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: #ffe600;
            position: absolute;
            right: 10px;
        }
    }

    .search-slice-warn {
        background-color: #ff9831;
        font-size: 13px;
        text-align: center;
        padding: 2px;
    }
}

.no-data {
    font-size: 13px;
    text-align: center;
    color: $ey-white;
    margin-top: 20px;
    margin-right: 10px;
}
