.ey-dashboards-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 10px;

  //.MuiTypography-body1 {
  //  border-left: 1px solid rgba(0, 0, 0, 0.15);
  //  border-right: 1px solid rgba(0, 0, 0, 0.15);
  //}

  .frame {
    flex: 1;
    border: none;
  }

  .options-wrapper {
    padding: 5px
  }

  .cf-options, .object-select-options {
    display: inline-block;
    padding: 5px;
    margin: 5px
  }

  .object-select-options .select-control {
    margin: 0 10px 5px;
  }

  .MuiExpansionPanel-rounded:last-child {
    margin-bottom: 50px
  }

  .MuiExpansionPanel-root {
    margin-bottom: 16px;

    &.Mui-expanded:first-child {
      margin-bottom: 30px;
    }

    &:not(.MuiExpansionPanelSummary-root) {
      & .Mui-expanded:first-child {
        margin-bottom: 50px;

        &.MuiExpansionPanelSummary-root {
          margin-bottom: 0px;
        }
      }
    }
  }

  .MuiAccordionDetails-root {
    display: block;
    background-color: #FFF !important;
  }
}
