.not-found {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;

  .error {
    color: $error;
    font-size: 50px;
    font-weight: bold;
  }

  p {
    font-size: 20px;
  }
}
