.ey-calculation-page {
  display: flex;
  height: 100vh;
}

.indicator-tree-wrapper {
  display: flex;
  flex-shrink: 0;
  flex-basis: 300px;
  flex-direction: column;
  overflow: scroll;
  height: 100vh;
}

.data-view-wrapper {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}

.indicator-header {
  padding: 0;
  color: $ey-black;
  font-size: 16px;
  font-weight: normal;
}

.ey-calculation-page .indicator-header,
.indicator-header-shim {
  margin: 10px 5px 10px 5px;
  height: 25px;
  user-select: none;
}

.aggregationType {
  border: none;
  display: none;

}

.visible {
  display: flex;
  border: 1px solid $border-color;
  border-radius: 2px;
  margin-right: 10px;
  background-color: $ey-yellow;
  white-space: nowrap;
  font-size: 14px;
  margin-left: 10px;
  min-width: 90px;
}