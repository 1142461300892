.ey-start-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  ul {
    list-style-type: none;
    margin: 30px;
    padding: 0;

    li {
      background-color: #dedee2;
      width: 400px;
      margin-bottom: 20px;
      user-select: none;
      display: block;
      overflow: hidden;

      &:hover {
        @include transition;
        background-color: $ey-yellow;
        cursor: pointer;
      }
    }
  }
}

.ey-start-page li > div {
  margin: 30px;
}
