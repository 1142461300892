.ey-group-by-toolbar {
  display: flex;
  flex-direction: row;
  .group-by-checkbox {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.ey-group-by-toolbar .group-by-checkbox {
  width: 85px;
}

.ey-group-by-toolbar .disabled {
  filter: grayscale(100%) opacity(50%);
}
