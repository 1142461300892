.MuiAccordion-root {
    border-radius: 0 !important;
}

.MuiAccordion-rounded {
    &:first-child {
        border-radius: 0 !important;
    }
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    margin: 0 !important;
}

.MuiAccordionSummary-root {
    min-height: 0 !important;
    padding: 0 0 0 16px !important;

    &.Mui-expanded {
        min-height: 0 !important;
    }
}

.MuiAccordionSummary-content {
    &.Mui-expanded {
        margin: 0 !important;
    }
}

.MuiAccordionSummary-content {
    margin: 0 !important;
}

.MuiAccordion-root {
    margin-bottom: 5px !important;

    &.Mui-expanded {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    &:before {
        content: '' !important;
        background: transparent !important;
    }
}

.MuiCollapse-wrapperInner {
    margin-left: 0 !important;
}

.MuiAccordionDetails-root {
    background: #eeeef2 !important;
}

.MuiCollapse-container {
    margin-left: 45px !important;

    .MuiAccordion-root {
        background: #f6f6f9 !important;
    }

    &.MuiCollapse-entered {
        .MuiAccordionSummary-root {
            min-height: 0 !important;
            padding: 2px 15px !important;
        }

        .inner .MuiAccordionDetails-root {
            background: #eeeef2 !important;
        }
    }
}
