
  .MuiAutocomplete-root {
    label {
      color: #2E2E38 !important;
    }

    .MuiOutlinedInput-root {
      border-radius: 0 !important;
      border-color: #2E2E38 !important;
    }
  }

  .MuiAutocomplete-paper {
    border-radius: 0 !important;
  }