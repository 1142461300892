@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,400i,700');

// Interstate
@font-face {
    font-family: 'EYInterstate';
    font-display: swap;
    src: url('../fonts/Interstate/EYInterstate-Regular.woff') format('woff');
}

@font-face {
    font-family: 'EYInterstate Bold';
    font-display: swap;
    src: url('../fonts/Interstate/EYInterstate-Bold.woff') format('woff');
}

@font-face {
    font-family: 'EYInterstate Light';
    font-display: swap;
    src: url('../fonts/Interstate/EYInterstate-Light.woff') format('woff');
}
