.ey-plot {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.ey-plot .plot-wrapper {
  flex: 1;
  display: flex;
  min-height: 0;
}

.ey-plot .plot-inner {
  flex: 1;
  margin: 5px;
  min-height: 0;
  overflow-y: scroll;
}

.ey-plot .plot-container {
  width: 0;
}

.ey-plot .groupby-toolbar-wrapper {
  margin-right: 15px;
}

.ey-plot .settings-toolbar {
  margin-right: 15px;
}

.ey-plot .chart-fill-toolbar {
  margin-right: 15px;
}

.ey-plot .chart-fill-toolbar .ey-tool-checkbox{
  width: 85px;
}

.plot-view-toolbar {
  display: flex;
  flex: 1;
  flex-direction: row;
}